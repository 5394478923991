const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
}

const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const color = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
        .padStart(6, '0');

    return `#${color}`;
}

const stringToLightColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Generate the color
    let color = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
        .padStart(6, '0');

    // Adjust to ensure the color is light
    // Light colors are generally high in brightness, so we can manipulate the color to keep values high.
    const r = Math.min(255, parseInt(color.substring(0, 2), 16) + 150); // Ensure red is bright
    const g = Math.min(255, parseInt(color.substring(2, 4), 16) + 150); // Ensure green is bright
    const b = Math.min(255, parseInt(color.substring(4, 6), 16) + 150); // Ensure blue is bright

    // Return the modified light color
    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0').toUpperCase()}`;
}



export {truncateText, stringToColor, stringToLightColor}