import React, {useEffect, useState} from 'react';
import './Calendar.css';
import Button from "@mui/material/Button";
import {ButtonGroup, Stack} from "@mui/material";
import {stringToLightColor} from "../../utils/misc";
import {formatHuman} from "../../utils/dateUtils";

const Calendar = ({items}) => {
    const [view, setView] = useState('month') // 'year', 'month', 'week', 'day'
    const [currentDate, setCurrentDate] = useState(new Date())
    const [events, setEvents] = useState(items)

    useEffect(() => {
        setEvents(items)
    }, [items])

    // Function to calculate the position of the current time line
    const calculateCurrentTimePosition = () => {
        const now = new Date();
        const minutesSinceMidnight = now.getHours() * 60 + now.getMinutes();
        return (minutesSinceMidnight / (24 * 60)) * 100; // Percentage of the day
    };

    const [currentTimePosition, setCurrentTimePosition] = useState(calculateCurrentTimePosition());

    const getFirstDayOfMonth = (firstDayOfMonth) => {
        const firstDay = firstDayOfMonth.getDay();
        return firstDay === 0 ? 6 : firstDay - 1; // Adjust so Monday = 1, Sunday = 7
    }

    const timeToRealTime = (timeStr) => {
        const parts = timeStr.split(':');
        return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
    }

    const getEventsForDay = (day) => events?.filter((event) => event.date === formatHuman(day))

    const renderEvent = (event) => <>{event?.price?.service?.name} | {event?.name}</>

    const getEventColor = (event) => stringToLightColor(event?.price?.service?.name)

    // Update current time line position every minute
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTimePosition(calculateCurrentTimePosition());
        }, 60000); // Update every minute

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    const changeView = (newView) => {
        setView(newView);
    }

    const changeDate = (offset) => {
        const newDate = new Date(currentDate);
        if (view === 'year') newDate.setFullYear(newDate.getFullYear() + offset);
        else if (view === 'month') newDate.setMonth(newDate.getMonth() + offset);
        else if (view === 'week') newDate.setDate(newDate.getDate() + offset * 7);
        else if (view === 'day') newDate.setDate(newDate.getDate() + offset);
        setCurrentDate(newDate);
    };

    const getCurrentName = () => {
        if (view === 'year') return currentDate.getFullYear()
        else if (view === 'month') return currentDate.getFullYear() + ' ' + currentDate.toLocaleString('hu-HU', {month: 'long'})
        else if (view === 'week') {
            const startOfWeek = new Date(currentDate);
            // Adjust startOfWeek to Monday (ISO week starts on Monday)
            startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() === 0 ? 6 : startOfWeek.getDay() - 1));
            const endDate = new Date(startOfWeek);
            endDate.setDate(startOfWeek.getDate() + 6); // Get the last date of the week

            const startFormatted = startOfWeek.toLocaleDateString('hu-HU', {
                weekday: 'long',
                day: 'numeric',
                month: 'long'
            });

            const endFormatted = endDate.toLocaleDateString('hu-HU', {
                weekday: 'long',
                day: 'numeric',
                month: 'long'
            });

            return `${startFormatted} - ${endFormatted}`;
        } else if (view === 'day') return currentDate.toLocaleDateString('hu-HU', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })
    }

    const renderHeader = () => (
        <Stack className="calendar-header" spacing={2}>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                className="calendar-header-row"
                spacing={2}
            >
                <Button onClick={() => changeDate(-1)}>Előző</Button>
                <span>{getCurrentName()}</span>
                <Button onClick={() => changeDate(1)}>Következő</Button>
            </Stack>
            <ButtonGroup aria-label="Nézet választó" className="calendar-header-view-buttons">
                <Button variant='contained' onClick={() => changeView('year')}>Év</Button>
                <Button variant='contained' onClick={() => changeView('month')}>Hónap</Button>
                <Button variant='contained' onClick={() => changeView('week')}>Hét</Button>
                <Button variant='contained' onClick={() => changeView('day')}>Nap</Button>
            </ButtonGroup>
        </Stack>
    )

    const renderCells = () => {
        switch (view) {
            case 'year':
                return renderYearView();
            case 'month':
                return renderMonthView();
            case 'week':
                return renderWeekView();
            case 'day':
                return renderDayView();
            default:
                return renderMonthView();
        }
    };

    const renderYearView = () => {
        const months = Array.from({length: 12}, (_, i) => new Date(currentDate.getFullYear(), i));
        return (
            <div className="calendar-year">
                {months.map((month, index) => (
                    <div key={index} className="calendar-month" onClick={() => {
                        setCurrentDate(new Date(currentDate.getFullYear(), index, 1))
                        setView('month')
                    }}>
                        {month.toLocaleString('hu-HU', {month: 'long'})}
                    </div>
                ))}
            </div>
        );
    };

    const renderMonthView = () => {
        const start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const today = new Date(); // Today's date for comparison
        const days = Array.from({length: end.getDate()}, (_, i) => new Date(start.getFullYear(), start.getMonth(), i + 1));

        return (
            <div className="calendar-month-grid">
                {['H', 'K', 'SZ', 'CS', 'P', 'SZ', 'V'].map((week, index) => (
                    <div key={index} className="calendar-month-header">{week}</div>
                ))}
                {Array.from({length: getFirstDayOfMonth(start)}).map((day, index) => (
                    <div key={index}></div>
                ))}
                {days.map((day, index) => {
                    // Check if the current day is today
                    const isToday =
                        day.getDate() === today.getDate() &&
                        day.getMonth() === today.getMonth() &&
                        day.getFullYear() === today.getFullYear();

                    return (
                        <div
                            key={index}
                            className={`calendar-day ${isToday ? 'today' : ''}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setCurrentDate(day);
                                setView('day');
                            }}
                        >
                        <span className={isToday ? 'today-circle' : ''}>
                            {day.getDate()}
                        </span>
                            {getEventsForDay(day).length !== 0 && (
                                <div className="calendar-event-count">
                                    {getEventsForDay(day).length + ' esemény'}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    const renderWeekView = () => {
        const startOfWeek = new Date(currentDate);
        const today = new Date();
        // Adjust startOfWeek to Monday (ISO week starts on Monday)
        startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() === 0 ? 6 : startOfWeek.getDay() - 1));
        const days = Array.from({length: 7}, (_, i) => new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i));

        return (
            <div className="calendar-week-grid">
                {days.map((day, index) => {
                    const eventsThisDay = getEventsForDay(day);
                    const isToday =
                        day.getDate() === today.getDate() &&
                        day.getMonth() === today.getMonth() &&
                        day.getFullYear() === today.getFullYear();
                    return (
                        <div key={index} className="calendar-day" onClick={(e) => {
                            e.stopPropagation();
                            setCurrentDate(day);
                            setView('day');
                        }}>
                            {day.toLocaleDateString('hu-HU', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })} {isToday ? '(ma)' : ''}
                            {getEventsForDay(day).length !== 0 && (
                                <div className="calendar-event-count">
                                    {getEventsForDay(day).length + ' esemény'}
                                </div>
                            )}

                            {/*{eventsThisDay.map((event) => (*/}
                            {/*    <div key={event.id} style={{ background: getEventColor(event), borderRadius: '3px', marginBottom: '2px' }} onClick={(e) => {*/}
                            {/*        e.stopPropagation();*/}
                            {/*        event?.onClick();*/}
                            {/*    }}>*/}
                            {/*        {renderEvent(event)}*/}
                            {/*    </div>*/}
                            {/*))}*/}
                        </div>
                    );
                })}
            </div>
        );
    };
    ;

    const renderDayView = () => {

        const hours = Array.from({length: 24}, (_, i) => `${i.toString().padStart(2, '0')}:00`);
        const eventsThisDay = getEventsForDay(currentDate);

        return (
            <div className="calendar-day-view">
                <h3>{currentDate.toLocaleDateString('hu-HU', {
                    weekday: 'long',   // Optional: e.g., "hétfő" (Monday)
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}</h3>
                <div className="day-view-grid">
                    {hours.map((time, index) => (
                        <div key={index} className="calendar-hour">
                            <span className="hour-label">{time}</span>
                            <div className="hour-line"></div>
                        </div>
                    ))}

                    {eventsThisDay.map((event) => {
                        const eventStart = timeToRealTime(event?.start_time);
                        const eventEnd = timeToRealTime(event?.end_time);
                        const eventDuration = (eventEnd - eventStart) / 60;

                        return (
                            <div
                                key={event.id}
                                onClick={() => event?.onClick()}
                                className="event-box"
                                style={{
                                    top: `${(eventStart / 60) * 100}px`,
                                    height: `${eventDuration * 100}px`,
                                    background: getEventColor(event),
                                }}
                            >
                                {renderEvent(event)}
                            </div>
                        );
                    })}

                    {/* Red line indicating the current time */}
                    <div
                        className="current-time-line"
                        style={{
                            top: `${currentTimePosition}%`,
                        }}
                    ></div>
                </div>
            </div>
        );
    };


    return (
        <div className="calendar">
            {renderHeader()}
            {renderCells()}
        </div>
    );
};

export default Calendar;
